@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

* {
    font-family: 'Lato', sans-serif;
}

.kanbas-navigation-ul,
.account-navigation-ul {

    list-style: none;

}

.logo-heading .first {
    font-family: Poppins;
    font-size: 27px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #394143;


}

.user-Heading-nav {
    font-family: Instrument Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-align: left;
    color: #9e9e9e;

}

.logo-heading .second {

    font-family: Poppins;
    font-size: 27px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #00BC62;

}

.account-navigation-li,
.account-navigation-ul {
    text-align: start;
    justify-content: start;
    align-items: start;
    padding-inline-start: 0;
}

.kanbas-navigation-ul {
    background-color: #dedede;
}

.kanbas-navigation-li {
    padding: 10px 0 0 0 !important;
    background-color: #dedede;
    padding: 10px 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.logo {
    filter: contrast(2000%);
    filter: saturate(8);
}

.kanbas-navigation-li .kanbas-navigation-a {
    color: white;
    text-decoration: none;

}

.active {
    background-color: white !important;
    color: rgba(200, 20, 20, 1) !important;
}

.icon-active {
    color: gray !important;
}

ul.kanbas-navigation-ul {
    padding-left: 0;
}

.kanbas-navigation-icon {
    color: white;
    display: block;
    font-size: 1.65em;
    font-weight: 100;
    color: rgba(200, 20, 20, 1);
    margin-bottom: 5px;
}

.account-navigation-a,
.account-navigation-li,
.mobile-menu {
    color: rgba(200, 20, 20, 1);
    margin-bottom: 10px;
    text-decoration: none;
}

hr {
    border: none;
    height: 0.75px;
    color: #aaa;
    background-color: #aaa;
}

.active-account-link {
    /* color: rgba(0, 0, 199, 1); */
    overflow: auto;
}

.active-account-link-border {
    border-left: 1px rgba(0, 0, 199, 1) solid;


}

body {
    font-family: "sans-serif" !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #28a745;
    border-color: #28a745;
}

input[type="checkbox"].custom-control-input {
    clip-path: circle(50% at 56% 50%);
    accent-color: green;

}



@media screen and (max-width: 480px) {
    table {
        border-collapse: collapse;
    }

    tr {
        display: block;
        float: left;
    }

    th,
    td {
        display: block;
        border: 1px solid black;
    }

    .kanbas-navigation-ul {
        flex-direction: row;
        display: flex;
        /* float:left; */
        margin-right: -5000px;
        padding-right: 5000px;
        margin-bottom: 0;
        padding-bottom: 0;
        width: 100%;

    }
}


.kanbas-navigation-ul {
    flex-direction: column;
    margin-bottom: -5000px;
    padding-bottom: 5000px;
    margin-right: 0;
    padding-right: 0;
    width: 300px !important;
}

.assignment-btn {
    border: 0.5px #666 solid;
    background-color: #eee;
    margin-right: 5px;
}

.btn-danger {
    background-color: #D41A2B !important;

}

.assignment-input {
    border-radius: 5px;
    border: 0.51px solid rgba(70, 70, 70, 0.3);
    padding: 5px 10px;
}

.thinner-icon {
    /* font-weight: 100;
    -webkit-text-stroke: 1px black;
    color: transparent; */

}

.assignment-rating {
    border: 0.5px solid rgba(70, 70, 70, 0.3);
    border-radius: 10px;
    padding: 0 10px;
}

table.grades-table tr td:nth-child(2n),
table.grades-table tr td:nth-child(2n+3) {
    text-align: center;
}

table.grades-table tbody tr td:nth-child(1) {
    color: rgba(200, 20, 20, 1);
}

.setting-li {
    padding: 5px 10px;
    border-bottom: 0.5px solid rgba(70, 70, 70, 0.3);
    text-align: center;
}

.setting-li .setting-a {
    color: rgba(200, 20, 20, 1);

    text-decoration: none;
    margin: 0;
    text-align: center;
}

.setting-ul {
    list-style: none;
    padding-left: 0;

    width: fit-content;
}

.active-setting {
    border-top: 0.5px solid rgba(70, 70, 70, 0.3);
    border-right: 0.5px solid rgba(70, 70, 70, 0.3);
    border-left: 0.5px solid rgba(70, 70, 70, 0.3);
    border-bottom: 0;
}

select {
    -webkit-appearance: menulist-button;
    height: 37px;
}

.responsive-show-top {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .responsive-hide-right {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .responsive-hide-left {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .responsive-show-top {
        display: block !important;
    }
}

.user-menu {
    font-family: Instrument Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-align: left;

}

.certificationCard {
    width: 100%;
}

/* Add this to your stylesheet */

li.profile-container {
    display: flex;
    align-items: center;
    margin-top: auto;
}

button.logout-btn {
    margin-right: 10px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer,
}

img.profile-pic {
    margin-right: 10px;
    border-radius: 50%;
}

div.end-nav-div {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.user-name {
    font-family: 'Instrument Sans';
    font-size: 14px;
    margin: 0;
}

.user-company {
    font-size: 12px;
    font-family: 'Instrument Sans';
    margin: 0;
}

/* Add this class to the div containing user information */



/* Manage Certification Page */

.certificationCard {
    padding: 30px 50px;
    border: 1px solid #ccc;
    border-radius: 2px;
    margin-bottom: 16px;
}

.heading_managecertifications {
    text-align: left;
    text-align: start;
}

.certification-container {
    display: flex;
    align-items: center;
}

.logo-container {
    margin-right: 10px;
}

.certification-details {
    display: flex;
    flex-direction: column;
}

.certification-name {
    font-size: 25px;
    font-weight: 500;
    font-family: Instrument Sans;
    margin: 0 0 0 20px;
}

.status-in-progress {
    font-size: 15px;
    font-family: Instrument Sans;
    font-weight: 300;
    margin: 10px 0 0 20px;
    color: green;
    vertical-align: bottom;
}

.todo-news-container {
    margin-top: 16px;
}

.todo-news-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.todo-news-divider {
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin: 16px 0 35px 0;
}

* {
    font-family: Instrument Sans;
}

.todo-news-column {
    padding: 10px 10px;
    width: 40%;

}

.spacer {
    display: inline;
    width: 20px;
}

.todo-news-item {
    text-align: left;
    margin-bottom: 8px;
    font-size: 22px;
}

.managecertifications {
    padding: 60px 55px 0px 55px;
}

.heading_managecertifications {
    font-family: Instrument Sans;
}

/* Read More Button */
.readMoreButton {
    display: flex;
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: green;
    font-size: 16px;
}

.certification-info {
    text-align: left;
    margin-left: 0px;
}

.certification_image {
    border: 1px solid #B9B9B9;
}

body,
html,
#root {
    height: 100%;
}

.d-flex {
    display: flex;
}

.kanbas-navigation-ul {
    list-style: none;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* Make the ul element grow to fill the remaining height */
}


/* defaultnav */

.user-menu {
    display: inline;
    margin-left: 15px
}


.questionReview {
    text-align: left;
    font-size: 17px;
    font-weight: 400;
    font-family: Instrument Sans;
}

.answerReview {
    text-align: left;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    font-family: Instrument Sans;
}

.reviewBox {
    border: 1px solid #b9b9b9;
    margin: 10px 5px;
    padding: 10px 10px;
    border-radius: 5px;
}

.open-pdf {
    color: #fff;
    background-color: #00BC62;
    padding: 5px 10px;
    font-size: 14px;
    border: 1px transparent solid;
    border-radius: 4px;
}

.agreenably-btn-edit {
    padding: 10px 20px 10px 20px;
    background-color: #001CE2;
    color: #fff;
    text-align: start;
    border: 0;
    border-radius: 5px;
    margin: 0 5px 5px 5px;
}

.agreenably-btn-submit {
    padding: 10px 20px 10px 20px;
    background-color: #00BC62;
    color: #fff;
    text-align: start;
    border: 0;
    border-radius: 5px;
    margin: 0 5px 5px 5px;
}

.user-menu-nav {
    display: "inline";
    margin-left: "5px";
    font-weight: "400";
    font-family: Instrument Sans;
    font-size: 20px;
}

.active-menu-item {
    /* background-color: #00BC62;  */
    /* color: #ffffff;  */
    font-weight: bold;
    width: fit-content;

}

.questionInCertification {
    margin: 10px 0;
}

.agreenably-btn-add-more {
    padding: 10px 20px 10px 20px;
    background-color: #fff;
    color: #00BC62;
    text-align: start;
    border: 1px solid #2d2d2d;
    border-radius: 5px;
    margin: 0 5px 5px 5px;
    display: flex;
    margin-left: auto;
}