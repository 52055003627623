@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

.booking-car-container {
  display: flex;
}

.booking-car-content {
  
  width: 100%;
}

.certification-card {
  justify-content: center;
  align-items: center;
  border: 1px solid #B9b9b9;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.certification-img {
  border-radius: 8px;
  margin-right: 20px;
}

.certification-details {
  display: inline;
}

.certification-card ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.certification-card li {
  position: relative;
  padding-left: 20px; 
}

.certificateCard {
  width: 60%;
}

.certificate-duration {
  font-family: Instrument Sans;
  font-size: 23px;
  margin: 10px 0 0 20px;
  color: #5e5e5e;
  vertical-align: bottom;
  font-weight: 200;
}

.certificationCard2 {
  padding: 10px 50px;
  margin-bottom: 16px;
  width: 100%;
}

.certification-process-ul {
  text-align: start;
}

.certification-process-li {
font-size: 32px;
}

.certification-process-li {
  position: relative;
  margin-bottom: 0px;
  padding-left: 20px;
}

.certification-process-li:before {
  content: "";
  position: absolute;
  top: 18px;
  left: 10px; 
  width: 15px; 
  height: 15px; 
  border: 1px solid #b9b9b9; 
  border-radius: 50%; 
  margin-left: -25px; 
}

.certification-process-li:after {
  content: "";
  position: absolute;
  top: 35px;
  height: 35px;
  left: -8px; 
  bottom: 0;
  width: 1px; 
  background-color: #b9b9b9; 
  margin-top: -2px; 
}

#certification-process-li-last:after{
  height: 0px;
}

.agreenably-btn {
  padding: 10px 120px 10px 20px;
  background-color: #00BC62;
  color: #fff;
  text-align: start;
  border: 0;
  border-radius: 5px;
  display: flex;
  margin-left: auto;
  margin-top: 22px;
  margin-bottom: 32px;
}

.certificate_questionnaire{
  padding: 20px 80px 5px 80px;
}
.certificate_questionnaire_heading {
  margin-top: 10px;
}

/* Question cards for questionnaire */

/* SingleChoiceQuestion.css */
.sectionStyle {
  margin-bottom: 20px;
  border: 1px solid #B9b9b9;
  padding: 40px;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.questionStyle {
  padding-top: 10px;
  margin: 10px 0;
  
}

.inputStyle {
  margin-right: 4px;
  appearance: none;  
}
.inputStyle::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #d9d9d9; 
  border-radius: 50%;
  margin-right: 8px;
}

.inputStyle:checked::before {
  background-color: #d9d9d9; 
  border-color: #d9d9d9; 
}
.errorText {
  color: red;
}
.question-type {
  text-align: left;
  font-size: 25px;
    font-weight: 500;
    font-family: Instrument Sans;
    
}
.question {
  text-align: left;
  font-size: 25px;
    font-weight: 400;
    font-family: Instrument Sans;
}

.answer-area {
  text-align: left;
  font-size: 20px;
  font-family: Instrument Sans;
  margin-top: 30px;
}
.labelStyle {
  margin: 0 40px 0 0;
}

.inputStyleMulti {
  margin-right: 4px;
  appearance: none;  
}
.inputStyleMulti::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #d9d9d9; 
  
  margin-right: 8px;
}

.inputStyleMulti:checked::before {
  background-color: #d9d9d9; 
  border-color: #d9d9d9; 
  content: '\2713'; 
  line-height: 1; /* Ensure the line height is normal */
  vertical-align: middle; /* Align vertically in the middle */
  color: #000; /* Color for the checkmark */
  font-size: smaller;
  display: inline;
  
}

.inputStyleText {
  border: 1px solid #b9b9b9; /* Set border color to #fdfdfd */
  border-radius: 2px;
  width: 100%;
  padding: 5px 10px;
  font-size: 15px;
  font-family: Instrument Sans;
  background-color: #fdfdfd; /* Set background color to #fdfdfd */
  /* Add any additional styling as needed */
}

.inputStyleText::placeholder {
  color: #b9b9b9; 
}

input.inputStyleFile[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #b9b9b9;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
}
.disabled_input {
  opacity: 0.5;
cursor: not-allowed;
background-color: #898989;
color: white;
}