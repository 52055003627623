@import url("https://fonts.googleapis.com/css2?family=Metal&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barrio&display=swap");

.Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}
.box-shadow1 {
  box-shadow: rgba(90, 0, 154, 0.605) 0px 1.5px;
  background-color: #390c46ec !important;
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  top: 0;
}
.bs2 {
  box-shadow: rgba(90, 0, 154, 0.605) 0px 2px 4px 0px,
  rgba(90, 0, 154, 0.605) 0px 2px 16px 0px;
}

h1 {
  font-size: 30px;
  font-weight: bold;
  
}
p {
  margin: 5px !important;
  text-align: left;
  padding: 0;
}
.header {
  padding: 12px;
}
.header .ant-btn {
  box-shadow: none !important;
  background-color: rgb(255, 255, 255) !important;
  color: rgba(90, 0, 154, 0.832) !important;
  border: 1px solid rgba(90, 0, 154, 0.862) !important;
  padding: 5px 15px !important;
  outline: none !important;
  font-family: "Courier New", Courier, monospace !important;
  margin-right: 2rem;
  margin-top: 0.4rem;
  font-weight: 600;
}
.logo-main {
  font-family: "Barrio";
}


.btn1 {
  box-shadow: none !important;
  background-color: white;
  color: rgba(90, 0, 154, 0.862);
  border: 1px solid #959595 !important;
  height: 35px;
  outline: none !important;
  padding: 5px;
  border-radius: 5px;
}

.car {
  border-radius: 5px;
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important;
  width: 272px;
  max-width: 272px;
  min-width: 272px;
  background-color: rgba(82, 0, 141, 0.519);
}
/* ------------------------------------login/Register-------------------------------------- */

.login {
  padding-top: 50px;
  min-height: max-content;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
}
.login label {
  color: #28a745 !important;
  font-weight: 500;
}
.login-form {
  border: 1px solid #2d2d2d;
  background-color: #fdfdfd;
  height: fit-content;
  width: fit-content;
  border-radius: 5px;
}
.login-form input {
  background-color: #e6e4e4 !important;
  border: none !important;
  color: rgb(26, 26, 26);
  min-width: 20rem !important;
  height: 40px;
}
.login-heading {
  color: #28a745 !important;
}

.registerAddCertificate .ant-select-selector {
  background-color: #e6e4e4 !important;
}

.registerRedirect {
  color: #28a745;
}
span.ant-select-selection-placeholder{
  color: rgb(120, 120, 120);
  padding: 5px 0;
}
.btn2 {
  box-shadow: none !important;
  background-color: white;
  color: #28a745;
  border: 1px solid #959595 !important;
  height: 2rem;
  padding: 5px 16px;
  font-weight: 500;
  outline: none !important;
}
.btn1:focus,
.btn2:focus,
.btnerror:focus {
  border: none;
}
/* --------------------------------------Booking page------------------------------------- */
.carimg2 {
  height: 100%;
  max-height: 100%;
  min-height: 380px;
  border-radius: 10px !important;
  width: 100%;
  max-width: 100%;
  min-width: 358px;
  padding-left: 15px;
}
.booking-icons {
  margin-top: -4px;
  font-size: 20px;
  color: white;
  margin-left: 4px !important;
  margin-right: 5px;
}
.car-data {
  max-width: 120.69px !important;
  min-width: 120.69px !important;
  width: 120.69px !important;
  font-size: 20px;
  /* background-color: #28d8d8; */
  /* padding: 3px 4px; */
  border-radius: 15px;
  color: white;
}
.car-headData {
  font-size: 20px;
  padding: 1.5px 5px;
  margin-left: 3rem;
  /* color: #14b9b9; */
  color: white;
  /* margin: 12px 2px; */
  margin-top: 3px !important;
}
.car-data2 {
  /* border-bottom: 1.8px solid #0eb9b9; */
  font-weight: 500;
}
/* -------------------------------------heading divider------------------------------------- */
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid white !important;
  /* border-top-color: inherit; */
  border-bottom: 0;
  transform: translateY(50%);
  content: "";
}
.ant-divider-inner-text h4 {
  margin-top: 4px;
  background-color: rgba(0, 0, 0, 0.397);
  padding: 1.5px 5px;
  border-radius: 5px;
}
.ant-picker-range {
  padding: 12px !important;
  background-color: #00BC62 !important;
  color: white !important;
  border: 1px solid white;
}

.ant-picker-input > input {
  color: white !important;
}
.ant-modal-title {
  color: #00BC62 !important;
}
.rcw-title {
  color: #00BC62;
}
/* ---------------------------------------------------------------------------------- */
/************************ Footer *********************/

ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: #390c46ec;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #DB91EFdf;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #e0dfdf;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #dfdfdf;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #DB91EFdf;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #f3cdfeec ;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #f3cdfeec ;
}
.footer-widget ul li a {
  color: #cac9ca;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #f3cdfeec;
  border: 1px solid #DB91EFdf;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #380b44d6 ;
  padding: 13px 20px;
  border: 1px solid #DB91EFdf;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #380b44d6;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: #DB91EFdf;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #DB91EFdf;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
.f-icons {
  font-size: 38px !important;
  color: #DB91EFdf !important;
  margin-left: 15px !important;
  margin-bottom: 5px !important;
}

.f-social-icons {
  font-size: 32px !important;
  margin-top: -13px;
  border-radius: 5px;
}
.facebook {
  color: #3b5998 !important;
  background-color: rgba(255, 255, 255, 0.87);
}
.twitter {
  color: #00acee !important;
  background-color: rgba(255, 255, 255, 0.87);
}
.linkedIn {
  color: #db4a39 !important;
  background-color: rgba(255, 255, 255, 0.87);
}
/* -------------------------------------Admin pages--------------------------------- */
.ant-form-item-required {
  color: white;
}
/* --------------------------------------Services----------------------------------- */
section {
  padding: 60px 0;
  min-height: 100vh;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-heading h2 {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.site-heading h2 span {
  color: #4B0082df;
}

.site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.site-heading h4::before {
  background: rgba(136, 61, 182, 0.84) none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.carousel-shadow .owl-stage-outer {
  margin: -15px -15px 0;
  padding: 15px;
}

.we-offer-area .our-offer-carousel .owl-dots .owl-dot span {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid;
  height: 15px;
  margin: 0 5px;
  width: 15px;
}

.we-offer-area .our-offer-carousel .owl-dots .owl-dot.active span {
  background: rgba(136, 61, 182, 0.84) none repeat scroll 0 0;
  border-color: rgba(136, 61, 182, 0.84);
}

.we-offer-area .item {
  background: #ffffff none repeat scroll 0 0;
  border-left: 2px solid rgba(136, 61, 182, 0.84);
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  overflow: hidden;
  padding: 30px;
  position: relative;
  z-index: 1;
}

.we-offer-area.text-center .item {
  background: #ffffff none repeat scroll 0 0;
  border: medium none;
  padding: 67px 40px 64px;
}

.we-offer-area.text-center .item i {
  background: #4B0082df none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  font-size: 40px;
  height: 80px;
  line-height: 80px;
  position: relative;
  text-align: center;
  width: 80px;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  margin-bottom: 25px;
}

.we-offer-area.text-center .item i::after {
  border: 2px solid #4B0082df;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 90px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 90px;
  z-index: -1;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.we-offer-area.item-border-less .item {
  border: medium none;
}

.we-offer-area .our-offer-items.less-carousel .equal-height {
  margin-bottom: 30px;
}

.we-offer-area.item-border-less .item .number {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 900;
  opacity: 0.1;
  position: absolute;
  right: 30px;
  top: 30px;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item,
.we-offer-area.center-active .single-item:nth-child(2n) .item {
  background: #4B0082df none repeat scroll 0 0;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item i,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item h4,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item p,
.we-offer-area.center-active .single-item:nth-child(2n) .item i,
.we-offer-area.center-active .single-item:nth-child(2n) .item h4,
.we-offer-area.center-active .single-item:nth-child(2n) .item p {
  color: #ffffff;
}

.we-offer-area .item i {
  color: #4B0082df;
  display: inline-block;
  font-size: 60px;
  margin-bottom: 20px;
}

.we-offer-area .item h4 {
  font-weight: 600;
  text-transform: capitalize;
}

.we-offer-area .item p {
  margin: 0;
}

.we-offer-area .item i,
.we-offer-area .item h4,
.we-offer-area .item p {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.we-offer-area .item::after {
  background: #60047aec none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 100%;
  z-index: -1;
}

.we-offer-area .item:hover::after {
  left: 0;
}

.we-offer-area .item:hover i,
.we-offer-area .item:hover h4,
.we-offer-area .item:hover p {
  color: #ffffff !important;
}

.we-offer-area.text-center .item:hover i::after {
  border-color: #ffffff !important;
}

.we-offer-area.text-center .item:hover i {
  background-color: #ffffff !important;
  color: #4B0082df !important;
}

.we-offer-area.text-left .item i {
  background: #4B0082df none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 60px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 30px;
  position: relative;
  width: 100px;
  z-index: 1;
  text-align: center;
}

.we-offer-area.text-left .item i::after {
  border: 2px solid #4B0082df;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 120px;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 120px;
}
.heading-service {
  color: rgba(136, 61, 182, 0.84) !important;
}
.icon-image {
  height: 105px;
  width: 105px;
  position: relative;
  top: -12px;
  left: -12px;
}
/* ----------------------------------------- Map ------------------------------------*/

.marker:hover {
  transform: scale(1.5);
}
/* ----------------------------------------contact form----------------------------- */
.contact {
  height: 70vh;
  max-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
  margin-top: 8rem;
}
.form-control {
  height: 3rem;
}
.message-area {
  height: 10rem !important;
}
.btn.btn-info{
  background-color: #4B0082df;
  border-color: #4B0082df;
}
/* 
--------------------------------------error--------------------------------------- */
.errorheading {
  text-align: center;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
  margin-top: 15rem;
}
.btnerror {
  box-shadow: none !important;
  background-color: white;
  color: #510e81df;
  border: 1px solid #959595 !important;
  font-size: 1.3rem;
  outline: none !important;
  padding: 5px;
  border-radius: 5px;
}
/* --------------------------------add car---------------------------------------- */

.btnAdd {
  padding: 0.3rem;
  font-size: 17px;
  border-radius: 5px;
  color: #4c0082c8;
  background-color: white;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .Main-heading-home {
    display: inline-block !important;
  }
}
.Main-heading-home {
  display: flex;
  justify-content: center;
  font-size: 44px !important;
  font-weight: 500 !important;
  margin-left: 2rem;
  color: white !important;
  margin-bottom: 2.5rem;
}
.Main-heading-home span {
  color: #4B0082;
}
.main-row {
  margin-top: 6.5rem;
  margin-bottom: 4.5rem;
}
